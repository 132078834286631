import { Box, Divider, Fab, Grid, IconButton } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Typography from '@material-ui/core/Typography'
import {
  CustomButton3,
  HeroBackground,
  HeroGrid,
  Section,
} from '@sites/shared-ui'
import { start_animation } from '@sites/shared-ui/util/gasp_animation'
import { StaticImage } from 'gatsby-plugin-image'
import gsap from 'gsap'
import React, { useLayoutEffect, useRef } from 'react'
import Header from '../components/header'
import ProjectSection from '../components/ProjectSection'
import SEO from '../components/seo'
import ZoomOutBanner from '../components/ZoomOutBanner'
import GetGraphQlImage from '../helpers/GetGraphQlImage'
import Layout from '../layouts'
import { Phone, WhatsApp } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  home: {
    display: 'block',
    width: '100vw',
    height: '100vh',
    overflow: 'hidden',
    position: 'relative',
  },
  home__bg: {
    position: 'absolute',
    zIndex: 0,
    height: '100%',
    width: '100%',
    left: 0,
    top: 0,
    objectFit: 'cover',
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  box: {
    width: 100,
    height: 100,
    borderRadius: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    backgroundColor: 'green',
    fontWeight: 600,
    color: '#fff',
  },
  promocard: {
    maxWidth: 420,
    width: '100%',
    padding: 18,
  },
  overlay: {
    position: 'absolute',
    bottom: 80,
    left: '50%',
    width: 180,
    marginLeft: -90,
    zIndex: 50,
    cursor: 'pointer',
    textAlign: 'center',
  },
}))

function scrollToDiv(divID) {
  gsap.to(window, { duration: 1, scrollTo:{ y: `${divID}`, offsetY: 44 } });
}

const IndexPage = ({ data, location, transitionStatus }) => {
  const classes = useStyles()
  const theme = useTheme()
  const is_mobile = useMediaQuery(theme.breakpoints.down('sm'))
  const bgref = useRef()

  // Animation Start
  const refs = useRef([])
  refs.current = []

  const addToRefs = el => {
    if (el && !refs.current.includes(el)) {
      refs.current.push(el)
    }
  }

  useLayoutEffect(() => {
    setTimeout(function() {
      refs.current.map(ref => start_animation(ref))
    }, 100)
  }, [])

  // Animation End

  const settings = {
    dots: true,
    arrows: false,
    autoplay: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  return (
    <Layout header="false" location={location}>
      <SEO title="Lotus group - Real Estate developer of Residential & Commercial Property in Ahmedabad" />

      <ZoomOutBanner
        homeBanner={
          <StaticImage
            className={classes.home__bg}
            src="../images/lotus_home.jpeg"
            alt="Lotus Group"
          />
        }
        homeContent={
          <>
            <StaticImage placeholder='none' src="../images/lotus_logo.png" alt="Lotus Group" />
            <Typography variant="h2" gutterBottom>
              Welcome to Lotus Group
            </Typography>
            <Fab  style={{ marginRight: 16 }} color="secondary" aria-label="add">
              <Phone fontSize="large" />
            </Fab>
            <Fab color="secondary" aria-label="add">
              <WhatsApp fontSize="large" />
            </Fab>
          </>
        }
      />

      <Header />
      <Section fullWidth>
        <HeroGrid
          leftmd={6}
          disablePadding
          spacing={6}
          rightmd={6}
          leftContent={
            <div>
              <Typography variant="h2" gutterBottom>
                ABOUT US
              </Typography>
              <Typography
                ref={addToRefs}
                className="gs_reveal"
                variant="subtitle1"
                gutterBottom
              >
                LOTUS GROUP is a well-known player in real estate industry, and their focus from day one has been to provide the best quality real estate products. Apart from that, they provide the best customer service and the uncompromising values. 
              </Typography>
              <Typography
                ref={addToRefs}
                className="gs_reveal"
                variant="subtitle1"
                gutterBottom
              >
                The company's main goal is to provide the best real estate services in all the areas they serve. Residents and their lifestyle are at the centre of our developments.
              </Typography>
              <CustomButton3
                ref={addToRefs}
                className="gs_reveal"
                style={{ marginTop: 32 }}
                href="https://apps.apple.com/in/app/learn-to-trace/id1003265796"
                rel="noreferrer"
                target="_blank"
              >
                READ MORE
              </CustomButton3>
            </div>
          }
          rightContent={
            <div ref={addToRefs} className="gs_reveal gs_reveal_fromRight">
              <GetGraphQlImage
                imageName="lotus_heights_isanpur_7.jpeg"
                imageAltName="About Lotus Heights"
              />
            </div>
          }
        />
      </Section>
      
      <div id="home-projects-section">
        <ProjectSection addToRefs={addToRefs} />
      </div>

      <Divider />

      <HeroBackground
        backgroundPosition="top"
        backgroundImg="/call_bg.jpeg"
        backgroundPosition="15% 15%"
        boxShadow
        parallax
      >
        <Box
          width={is_mobile ? '100%' : '75%'}
          mx="auto"
          color="#000"
          bgcolor="#fff"
          p={3}
        >
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            <Grid item xs={12} md={8}>
              <Typography variant="h3" gutterBottom>
                Looking for a dream home?
              </Typography>
              <Typography variant="h5">
                We can help you realize your dream of a new home
              </Typography>
            </Grid>
            <Grid item xs xs={12} md={4}>
              <CustomButton3 onClick={ () => scrollToDiv('#home-projects-section')}>
                Explore Properties
              </CustomButton3>
            </Grid>
          </Grid>
        </Box>
      </HeroBackground>
    </Layout>
  )
}

export default IndexPage
