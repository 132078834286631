import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"

export const start_animation = (elem) => {
    if (elem.classList.contains("gs_zoom")){
      ScrollTrigger.create({
          trigger: elem,
          onEnter: function() { animateFrom(elem,1,null,0.5,'zoom',0.75,1) }, 
          onEnterBack: function() { animateFrom(elem,1,null,0.5,'zoom',0.75,1) },
          onLeave: function() { animateFrom(elem,1,null,0.5,'zoom',1,1) } // assure that the element is hidden when scrolled into view
      });
    }else{
      hide(elem);
      ScrollTrigger.create({
          trigger: elem,
          onEnter: function() { animateFrom(elem) }, 
          onEnterBack: function() { animateFrom(elem, -1) },
          onLeave: function() { hide(elem) } // assure that the element is hidden when scrolled into view
      });
    }

    
 }


function animateFrom(elem, direction, speed, duration, type, scaleFrom, scaleTo) {
 var aniSpeed = 0;
 var aniDuration = 0;
 var aniScaleFrom = 0;
 var aniScaleTo = 0;
 if(type == 'zoom'){
  aniDuration = (duration) ? duration : 20;
  aniScaleFrom = (scaleFrom) ?  scaleFrom : 1.25;
  aniScaleTo =  (scaleTo) ?  scaleTo : 1.0;
 }
 else{
  aniSpeed =  (speed) ? speed : 100;
  aniDuration = (duration) ? duration : 1.25;
 }
 direction = direction || 1;
 
 
 
 if(type === 'zoom'){

  if(direction === -1){
    gsap.fromTo(elem, { scale:aniScaleTo }, {duration: aniDuration, scale:aniScaleFrom, ease:"ExpoScaleEase(0.1, 8)"});
  }
  else{
    gsap.fromTo(elem, { scale:aniScaleFrom }, {duration: aniDuration, scale:aniScaleTo, ease:"ExpoScaleEase(0.1, 8)"});
  }
  
 }
 else{
  var x = 0,
  y = direction * aniSpeed;
  if(elem.classList.contains("gs_reveal_fromLeft")) {
    x = (-1 * aniSpeed);
    y = 0;
  } else if (elem.classList.contains("gs_reveal_fromRight")) {
    x = aniSpeed;
    y = 0;
  }
  elem.style.transform = "translate(" + x + "px, " + y + "px)";
  elem.style.opacity = "0";

  gsap.fromTo(elem, {x: x, y: y, autoAlpha: 0}, {
    duration: aniDuration, 
    x: 0,
    y: 0, 
    autoAlpha: 1, 
    ease: "expo", 
    overwrite: "auto",
  });
 }
}

function hide(elem) {
 gsap.set(elem, {autoAlpha: 0});
}
