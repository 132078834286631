import React, { useRef, useLayoutEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Section } from '@sites/shared-ui'
import gsap from 'gsap'

const useStyles = makeStyles(theme => ({
  home__bg: {
    display: 'block',
    width: '100vw',
    height: '100vh',
    overflow: 'hidden',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      //height: 500,
    },
  },
  home__filter: {
    position: 'absolute',
    zIndex: 0,
    height: '100%',
    width: '100%',
    background: 'rgba(17,21,23,.22)',
  },
  home__content: {
    position: 'absolute',
    top: '50%',
    height: 240,
    marginTop: -120,
    left: 0,
    right: 0,
    margin: 'auto',
    color: '#fff',
    textAlign: 'center',
    fontWeight: 400,
  },
}))

const ZoomOutBanner = ({ homeBanner, homeContent }) => {
  const bgref = useRef()
  const classes = useStyles()

  useLayoutEffect(() => {
    setTimeout(function() {
      gsap.fromTo(
        bgref.current.children,
        { scale: 1.25 },
        { duration: 20, scale: 1.0, ease: 'expoScale(0.1, 8)' }
      )
    }, 100)
  }, [])

  return (
    <Section className={classes.home__bg} fullWidth disablePadding>
      <div>
        <div ref={bgref}>{homeBanner}</div>
        <div className={classes.home__filter}></div>
        <div className={classes.home__content}>{homeContent}</div>
      </div>
    </Section>
  )
}

export default ZoomOutBanner
