import React from 'react'
import { Box, Button, Chip } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { CustomButton3, Icon, Section, Title } from '@sites/shared-ui'
import { graphql, StaticQuery } from 'gatsby'
import GetGraphQlImage from '../helpers/GetGraphQlImage'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Link from 'gatsby-link';
import { isblank } from '@sites/shared-ui/util/commonfunctions'

const useStyles = makeStyles(theme => ({
  infoBox: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  spacious: {
    alignItems: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
      marginBottom: 32,
    },
  },
}))

const ProjectSection = ({ addToRefs }) => {
  const classes = useStyles()
  const theme = useTheme()
  const is_mobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Section>
      <div ref={addToRefs} className="gs_reveal">
        <Title align="center" title="OUR PROJECTS" titlevariant="h2" />
      </div>

      <Section narrowPadding>
        <StaticQuery
          query={graphql`
            query ProjectQuery {
              allProjectsJson {
                edges {
                  node {
                    id
                    title
                    sub_title
                    status
                    location
                    banner
                  }
                }
              }
            }
          `}
          render={data => (
            <>
              {data.allProjectsJson.edges.map((project, index) => {
                const image = project.node.banner

                if (index % 2 || is_mobile) {
                  return (
                    <Grid key={`project-${index}`} container alignItems="stretch">
                      <Grid item md={6}>
                        <div ref={addToRefs} className="gs_zoom">
                          <GetGraphQlImage
                            imageName={image}
                            imageAltName={project.node.title}
                          />
                        </div>
                      </Grid>
                      <Grid item md={6}>
                        <Box
                          color="#fff"
                          className={`${classes.infoBox} ${classes.spacious}`}
                        >
                          <Typography
                            ref={addToRefs}
                            className="gs_reveal"
                            variant="h2"
                            gutterBottom
                          >
                            {project.node.title}
                          </Typography>
                          <Typography
                            ref={addToRefs}
                            className="gs_reveal"
                            variant="subtitle1"
                            gutterBottom
                          >
                            <Icon name="star" size={18} />{' '}
                            {project.node.sub_title}
                          </Typography>
                          <Typography
                            ref={addToRefs}
                            className="gs_reveal"
                            variant="subtitle1"
                          >
                            <Icon name="map" size={18} />{' '}
                            {project.node.location}
                          </Typography>
                          {!isblank(project.node.status) &&
                          <Chip style={{ width: 150, marginTop: 8}} label={project.node.status} /> }
                          <CustomButton3
                            href={`/${project.node.title.replace(/\s+/g, "-").toLowerCase()}`}
                            style={{ width: 150, marginTop: 32}}>
                            View Project
                          </CustomButton3>
                        </Box>
                      </Grid>
                    </Grid>
                  )
                } else {
                  return (
                    <Grid key={`project-${index}`} container alignItems="stretch">
                      <Grid item md={6}>
                        <Box color="#fff" className={classes.infoBox}>
                          <Typography
                            ref={addToRefs}
                            className="gs_reveal"
                            variant="h2"
                            gutterBottom
                          >
                            {project.node.title}
                          </Typography>
                          <Typography
                            ref={addToRefs}
                            className="gs_reveal"
                            variant="subtitle1"
                            gutterBottom
                          >
                            <Icon name="star" size={18} />{' '}
                            {project.node.sub_title}
                          </Typography>
                          <Typography
                            ref={addToRefs}
                            className="gs_reveal"
                            variant="subtitle1"
                          >
                            <Icon name="map" size={18} />{' '}
                            {project.node.location}
                          </Typography>
                          {!isblank(project.node.status) &&
                          <Chip style={{ width: 150, marginTop: 8}} label={project.node.status} /> }
                          <CustomButton3
                            href={`/${project.node.title.replace(/\s+/g, "-").toLowerCase()}`}
                            style={{ width: 150, marginTop: 32}}>
                            View Project
                          </CustomButton3>
                        </Box>
                      </Grid>

                      <Grid item md={6}>
                        <div ref={addToRefs} className="gs_zoom">
                          <GetGraphQlImage
                            imageName={image}
                            imageAltName={project.node.title}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  )
                }
              })}
            </>
          )}
        />
      </Section>
    </Section>
  )
}

export default ProjectSection
